import internalFetch from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

export const getVoluumAlertsConfigService = async () => await internalFetch.get('/voluum/config/', {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getVoluumAlertsService = async project => await internalFetch.post('/voluum/alerts/', {"project": project ? project : null}, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const updateVoluumAlertService = async data => await internalFetch.post('/voluum/update/', {"data": data}, {
    headers: { 'Authorization': getAuthorizationHeader() }
});

export const getClosedCampaignsService = async data => await internalFetch.post('/voluum/closed-campaigns/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getClosedCampaignPathService = async state => await internalFetch.post('/voluum/closed-campaigns-path/', {"offerId": state.offerId, "date": state.date}, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getClosedCampaignFlowsService = async state => await internalFetch.post('/voluum/closed-campaigns-flows/', {"offerId": state.offerId, "date": state.date}, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getClosedCampaignCampaignsService = async state => await internalFetch.post('/voluum/closed-campaigns-campaigns/', {"offerId": state.offerId, "date": state.date}, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const closeVoluumOffers = async data => await internalFetch.post('/voluum/close-voluum-offers/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getVoluumReportsService = async data => await internalFetch.post('/voluum/reports/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const getSettingsViewService = async data => await internalFetch.post('/voluum/get-settings/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const saveSettingsViewService = async data => await internalFetch.post('/voluum/save-settings/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});
export const deleteSettingsViewService = async data => await internalFetch.post('/voluum/delete-settings/', data, {
    headers: { 'Authorization': getAuthorizationHeader() }
});