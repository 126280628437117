import axios from 'axios';
import internalFetch from "./axios-instance";
import {getAuthorizationHeader} from "../helpers/get-token";

const apiUrl = localStorage.getItem('apiUrl');
const token = localStorage.getItem("auth_token");

export const addNewUserService = async data => await internalFetch.post('/register/', {"data": data, "token": getAuthorizationHeader()});
export const isAuthService = async (token) => await internalFetch.post('/auth/', {"token": token});
export const logInService = async (email, password) => await internalFetch.post('/login/', {"email": email, "password": password});
export const logOutService = async (token) => await internalFetch.post('/logout/', {"token": token});
export const getProjectConnectionsService = async () => await internalFetch.get(`/project-connection`);
export const getAccountsInformationService = async project => await internalFetch.get(`/accounts?project=${project}`);

// export const getAccountsInformationService = async project => {
//   const response = await fetch(`${apiUrl}accounts?project=${project}`, {
//       method: 'GET',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       }
//   });

//   if (!response.ok) {
//       throw new Error(`Error fetching data: ${response.statusText}`);
//   }

//   const data = await response.json();
//   return data;
// };