import { useEffect } from "react";
import "./pagination.scss";

const Pagination = ({
                        totalPagesCount,
                        currentPage,
                        setCurrentPage,
                        infiniteScroll = false,
                    }) => {
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    }

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    }

    const handleToFirstPage = () => {
        setCurrentPage(1);
    }

    const handleToLastPage = () => {
        setCurrentPage(totalPagesCount);
    }

    return (
        <div className="pagination-wrap">
            <button
                className={`pagination-btn ${currentPage === 1 ? "disabled" : ""}`}
                onClick={handleToFirstPage}
                disabled={currentPage === 1}
            >
                <img src={`${process.env.PUBLIC_URL}/images/pagination-first.svg`} alt="Pagination First"/>
            </button>
            <button
                className={`pagination-btn ${currentPage === 1 ? "disabled" : ""}`}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
            >
                <img src={`${process.env.PUBLIC_URL}/images/pagination-prev.svg`} alt="Pagination Previous"/>
            </button>
            { 
                infiniteScroll
                    ? <div>{currentPage}</div>
                    : <div>{currentPage} of {totalPagesCount}</div>
            }
            <button
                className={`pagination-btn ${currentPage === totalPagesCount ? "disabled" : ""}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPagesCount}
            >
                <img src={`${process.env.PUBLIC_URL}/images/pagination-next.svg`} alt="Pagination Next"/>
            </button>
            {
                infiniteScroll
                    ? null
                    : <button
                        className={`pagination-btn ${currentPage === totalPagesCount ? "disabled" : ""}`}
                        onClick={handleToLastPage}
                        disabled={currentPage === totalPagesCount}
                    >
                        <img src={`${process.env.PUBLIC_URL}/images/pagination-last.svg`} alt="Pagination Last"/>
                    </button>
            }
        </div>
    )
}

export default Pagination;