import {useEffect, useState} from "react";
import Select from "react-select";
import ClearFilters from "../buttons/ClearFilters";
import "./affiliate-network-offers-table-filter.scss";
import {InputMask} from "@react-input/mask";
import Loader from "../loaders/TableLoader";
import {filterConditionOptions, filterConditionOptionsForJson} from "../../pages/AffiliateNetworkOffers/table-options"

const AffiliateNetworkOffersTableFilter = ({
                                               orderByOptions = [],
                                               orderDirectionOptions = [],
                                               searchByColumnOptions = [],
                                               setSearchByColumnOptions,
                                               selectedFilters,
                                               handleGettingOfferWithSearchParams,
                                               changeSelectedFilters,
                                               clearSearchFilters,
                                               setFilterParams,
                                               filterParams,
                                               setTags,
                                               tags,
                                               selectedANAccount,
                                               selectedANType,
                                               isLoading,
                                               setIsLoading,
                                               setFilters,
                                               filters,
                                               defaultFilter,
                                               setJoinByGeneralInTags,
                                               joinByGeneralInTags,
                                               setJoinByGeneral,
                                               joinByGeneral
                                           }) => {
    const [selectedColumnToSearch, setSelectedColumnToSearch] = useState([]);
    const [selectedSearchCondition, setSelectedSearchCondition] = useState([]);
    const [conditionOptions, setConditionOptions] = useState([]);
    const [errors, setErrors] = useState("");

    const joinByOptions = [{value: 'AND', label: 'AND'}, {value: 'OR', label: 'OR'}];

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "12px",
            lineHeight: "1.2",
            minHeight: "26px",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px",
        }),
        clearIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px",
        }),
        multiValueLabel: (baseStyles, state) => ({
            ...baseStyles,
            padding: "1px",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "6",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "12px",
            lineHeight: "1.2",
            "&:hover": {
                cursor: "pointer",
            },
        }),
    }

    useEffect(() => {
        setConditionOptions(filterConditionOptions);

        const temp = searchByColumnOptions.map(item => ({
            ...item,
            isDisabled: false
        }));

        setSearchByColumnOptions(temp);
    }, []);

    const getOffersWithSearchParams = () => {
        let temp = {};
        let tempTag = [...tags];
        let preparedData = [];

        selectedColumnToSearch.forEach((search, index) => {

            let correctOperator = selectedSearchCondition[index].value || '';
            let formattedValue;
            let groupValues = [];

            for(let value of filters[index].groupValues) {
                if (value !== '') {
                    groupValues.push(value);
                }
            }
            
            if(filters.length > 0) {
                formattedValue = filters[index].value; 
            } else {
                formattedValue = '';
            }

            if (search?.value && correctOperator && formattedValue) {
                if (correctOperator === "=" && (search.value === "offer_domain" || search.value === "offer_country")) {
                    correctOperator = "in_json";
                }
                
                if (correctOperator === "!=" && (search.value === "offer_domain" || search.value === "offer_country")) {
                    correctOperator = "not_json";
                }

                if (correctOperator === "not like" && (search.value === "offer_domain" || search.value === "offer_country")) {
                    correctOperator = "not_json";
                }

                if (correctOperator === "in_json" && (search.value === "available_affiliate_networks")) {
                    correctOperator = "like";
                }

                if (correctOperator === "not_json" && (search.value === "available_affiliate_networks")) {
                    correctOperator = "not like";
                }

                if (correctOperator === "like" || correctOperator === "not like") {
                    formattedValue = `%${formattedValue}%`;
                    if(groupValues.length > 0) {
                        let temp = groupValues.map((item) => {
                            item = `%${item}%`;
                            return item;
                        })
                        groupValues = temp;
                    }
                }

                if(search.value === 'deeplink') {

                    function stringToBoolean(str) {
                        return str === 'true';
                    }

                    formattedValue = formattedValue.replaceAll('%', '');

                    if(formattedValue === 'true' || formattedValue === 'false') formattedValue = stringToBoolean(formattedValue);

                    if(groupValues.length > 0) {
                        let temp = groupValues.map((item) => {
                            item = item.replaceAll('%', '');
                            if(item === 'true' || item === 'false') item = stringToBoolean(item);
                            return item;
                        })
                        groupValues = temp;
                    }

                    if(correctOperator === "like") correctOperator = '=';
                    if(correctOperator === "not like") correctOperator = '!=';
                }

                groupValues.push(formattedValue);

                preparedData.push({
                    id: search.id,
                    column: search.value,
                    operator: correctOperator,
                    value: groupValues,
                    join_by: filters[index].groupJoin
                })

                //remove tag before update all tags
                tempTag = tempTag.filter(tag => tag.name !== search.value);

                const tagValues = groupValues.map((item) => {
                    if(typeof item === 'boolean') {
                        item = item.toString();
                    }
                    return item.replaceAll('%', '');
                });
                
                tempTag.push({
                    name: search.value,
                    operator: selectedSearchCondition[index].label,
                    value: tagValues,
                    join_by: filters[index].groupJoin
                });
            }
        });
        
        let result = {
           ...filterParams
        };

        result.global_join_by = joinByGeneral;

        // add new filters params
        preparedData.forEach(item => {
            result[item.column] = {
                operator: item.operator,  
                value: item.value,   
                join_by: item.join_by               
            };
        });

        temp = Object.assign(result);

        setFilterParams({...temp});
        setFilters(defaultFilter);
        setJoinByGeneralInTags(joinByGeneral);
        setTags(tempTag);
        handleGettingOfferWithSearchParams(temp);
    }

    const filterConditionValues = (option) => {
        if (option.value === "offer_domain" || option.value === "offer_country" || option.value === "available_affiliate_networks") {
            setConditionOptions(filterConditionOptionsForJson)
        } else {
            setConditionOptions(filterConditionOptions)
        }
    }

    const handleRemoveColumnFromSearch = (name) => {
        let temp = [...tags];
        let tempFilters = {...filterParams};
        let filteredTags = temp.filter(t => t.name !== name);

        delete tempFilters[name];
        setTags(filteredTags);
        setFilterParams(tempFilters);
        handleGettingOfferWithSearchParams(tempFilters);
        setFilters(defaultFilter);
    }

    const addNewFilterHandler = (type) => {
        const newId = filters.length < 10 ? filters[filters.length - 1].id + 1 : 1;
        setFilters([...filters, { 
            id: newId, 
            type: type, 
            value: '',
            groupJoin: 'AND',
            groupValues: []
        }]);
    }

    const addNewGroupValueHandler = (id) => {

        const updatedFilters = filters.map((filter) => {
            if (filter.id === id) {
                return {
                    ...filter,
                    groupValues: [...filter.groupValues, '']
                };
            }
            return filter;
        });

        setFilters(updatedFilters);
    }

    const setJoinByGroup = (value, id) => {
        const updatedFilters = filters.map((filter) => {
            if (filter.id === id) {
                return {
                    ...filter, 
                    groupJoin: value
                };
            }
            return filter;
        });
 
        setFilters(updatedFilters);
    }

    const removeNewFilterHandler = (id) => {
        setFilters(filters.filter(filter => filter.id !== id));

        const updatedValues = filters.filter(item => item.id !== id);
        setFilters(updatedValues);

        const updatedColumnToSearch = selectedColumnToSearch.filter(item => item.id !== id);
        setSelectedColumnToSearch(updatedColumnToSearch);

        const updateSelectedSearchCondition = selectedSearchCondition.filter(item => item.id !== id);
        setSelectedSearchCondition(updateSelectedSearchCondition);
    }

    const removeGroupValueHandler = (id, index) => {
        setFilters(prevValues =>
            prevValues.map(obj => {
                if (obj.id === id) {
                    const updatedGroupValues = obj.groupValues.filter((_, i) => i !== index);
                    
                    return {
                        ...obj,
                        groupValues: updatedGroupValues
                    };
                }
                return obj;
            })
        );
    }

    const addNewSearchCondition = (options, id) => {
        const newCondition = {
            id: id,
            ...options
        };

        setSelectedSearchCondition(prevConditions => {
        const conditionExists = prevConditions.some(condition => condition.id === id);
    
            if (conditionExists) {
                return prevConditions.map(condition => 
                    condition.id === id ? newCondition : condition
                );
            } else {
                return [...prevConditions, newCondition];
            }
        });
    };

    const updateSearchValue = (value, id) => {

        setFilters(prevValues => {
            const existingObject = prevValues.find(obj => obj.id === id);
            
            if (existingObject) {
                return prevValues.map(obj =>
                    obj.id === id
                        ? { ...obj, value: value }
                        : obj
                );
            }
        });
    }

    const updateGroupSearchValues = (newValue, id, index) => {
        setFilters(prevValues =>
            prevValues.map(obj => {
                if (obj.id === id) {
                    const updatedGroupValues = [...obj.groupValues];
                    updatedGroupValues[index] = newValue;
                    return {
                        ...obj,
                        groupValues: updatedGroupValues
                    };
                }
                return obj;
            })
        );
    };

    const getGroupSearchValue = (index, id) => {
        const foundObject = filters.find(obj => obj.id === id);
        
        if (foundObject && foundObject.groupValues[index] !== undefined) {
            return foundObject.groupValues[index];
        }
        return null;
    };

    const getSearchValueById = (id) => {
        const foundObject = filters.find(item => item.id === id);
        return foundObject ? foundObject.value : null;
    };

    const updateSelectedColumnToSearch = (options, id) => {

        const newColToSearch = {
            id: id,
            ...options
        };

        setSelectedColumnToSearch(prevOption => {
        const optionExists = prevOption.some(condition => condition.id === id);
        
            if (optionExists) {
                return prevOption.map(condition => 
                    condition.id === id ? newColToSearch : condition
                );
            } else {
                return [...prevOption, newColToSearch];
            }
        });
    }

    useEffect(() => {
        // check and update search options list
        updateSearchOptionsList();
    }, [selectedColumnToSearch]);

    const findSelectedColumnToSearch = (id) => {
        const foundObject = selectedColumnToSearch.find(item => item.id === id);
        return foundObject ? foundObject : null;
    }

    const updateSearchOptionsList = () => {

        const updatedOptions = searchByColumnOptions.map(option => {
            const isInSearchValue = selectedColumnToSearch.some(item => item.value === option.value);
            return {
              ...option,
              isDisabled: isInSearchValue
            };
        });
        setSearchByColumnOptions(updatedOptions);
    }

    return (

        <div className="data-table-filters-wrap">
            


            <div className="data-table-filter-wrap data-table-multiple-filter-wrap">
                <div className="filter-inputs-wrap">
                    {filters.map((filter, index) => (
                        <div key={filter.id} className="single-filter-wrap">

                            {index === 0 && (
                                <div className="filter-title">
                                    <p>Where</p>
                                </div>
                            )}

                            {index === 1 && (
                                <Select
                                    className={"sort-select join-by-select"}
                                    styles={selectStyles}
                                    options={joinByOptions}
                                    defaultValue={joinByOptions.filter(option => 
                                        option.label === joinByGeneral)
                                    }
                                    isSearchable={true}
                                    isClearable={false}
                                    name={"join_by"}
                                    onChange={(option) => {
                                        setJoinByGeneral(option.label);
                                    }}
                                />
                            )}

                            {index > 1 && (
                                <div className="filter-title">
                                    <p className="join-by-title">{ joinByGeneral }</p>
                                </div>
                            )}

                            {filter.type === 'filter' && (
                                <div className="single-filters-cover">
                                    <div className="filter-cover">
                                        <Select
                                            className={"sort-select search-in-column-select"}
                                            styles={selectStyles}
                                            options={searchByColumnOptions.sort((a, b) => a.value.localeCompare(b.value))}
                                            isSearchable={true}
                                            isClearable={true}
                                            name={"search_in_column"}
                                            onChange={(option) => {
                                                updateSelectedColumnToSearch(option, filter.id)
                                                filterConditionValues(option)
                                                updateSearchValue('', filter.id);
                                            }}
                                        />

                                        <Select
                                            className={"sort-select"}
                                            styles={selectStyles}
                                            options={conditionOptions}
                                            defaultValue={selectedSearchCondition.find(condition => condition.id === filter.id)}
                                            isSearchable={false}
                                            name={"filter_conditions"}
                                            onChange={(options) => {
                                                addNewSearchCondition(options, filter.id);
                                            }}
                                        />

                                        {findSelectedColumnToSearch(filter.id)?.type === "date" && (
                                            <InputMask
                                                mask="yyyy-mm-dd hh:mm:ss"
                                                placeholder="yyyy-mm-dd hh:mm:ss"
                                                className="sort-input"
                                                replacement={{d: /\d/, m: /\d/, y: /\d/, h: /\d/, s: /\d/,}}
                                                separate
                                                onChange={e => {
                                                    updateSearchValue(e.target.value, filter.id)
                                                }}
                                            />
                                        )}

                                        {findSelectedColumnToSearch(filter.id)?.type !== "date" && (
                                            <div className={"search-bar-wrapper"}>
                                            <input
                                                type="text"
                                                className="sort-input"
                                                value={getSearchValueById(filter.id) || ''}
                                                onChange={(e) => {
                                                    updateSearchValue(e.target.value, filter.id);
                                                }}
                                                placeholder="Search..."
                                            />
                                                {errors && <p className="error-message">{errors}</p>}
                                            </div>
                                        )}

                                        {filters && filters.length > 1 && (
                                            <button className="close-btn"
                                                onClick={() => removeNewFilterHandler(filter.id)}
                                            > +
                                            </button>
                                        )} 
                                    </div>
                                </div>
                            )}

                            {filter.type === 'group' && (
                                <div className="group-filters-cover">
                                    <div className="group-cover">
                                        <Select
                                            className={"sort-select search-in-column-select"}
                                            styles={selectStyles}
                                            options={searchByColumnOptions.sort((a, b) => a.value.localeCompare(b.value))}
                                            isSearchable={true}
                                            isClearable={true}
                                            name={"search_in_column"}
                                            onChange={(option) => {
                                                updateSelectedColumnToSearch(option, filter.id)
                                                filterConditionValues(option)
                                                updateSearchValue('', filter.id)
                                            }}
                                        />

                                        <Select
                                            className={"sort-select"}
                                            styles={selectStyles}
                                            options={conditionOptions}
                                            defaultValue={selectedSearchCondition.find(condition => condition.id === filter.id)}
                                            isSearchable={false}
                                            name={"filter_conditions"}
                                            onChange={(options) => {
                                                addNewSearchCondition(options, filter.id);
                                            }}
                                        />

                                        {findSelectedColumnToSearch(filter.id)?.type === "date" && (
                                            <InputMask
                                                mask="yyyy-mm-dd hh:mm:ss"
                                                placeholder="yyyy-mm-dd hh:mm:ss"
                                                className="sort-input"
                                                replacement={{d: /\d/, m: /\d/, y: /\d/, h: /\d/, s: /\d/,}}
                                                separate
                                                onChange={e => {
                                                    updateSearchValue(e.target.value, filter.id)
                                                }}
                                            />
                                        )}

                                        {findSelectedColumnToSearch(filter.id)?.type !== "date" && (
                                            <div className={"search-bar-wrapper"}>
                                            <input
                                                type="text"
                                                className="sort-input"
                                                value={getSearchValueById(filter.id) || ''}
                                                onChange={(e) => {
                                                    updateSearchValue(e.target.value, filter.id);
                                                }}
                                                placeholder="Search..."
                                            />
                                                {errors && <p className="error-message">{errors}</p>}
                                            </div>
                                        )}

                                        {filters && filters.length > 1 && (
                                            <button className="close-btn"
                                                onClick={() => removeNewFilterHandler(filter.id)}
                                            > +
                                            </button>
                                        )} 
                                    </div>
                                    {filter.groupValues && filter.groupValues.map((addValue, index) => (
                                        <div key={index} className="group-cover additional-value">
                                            
                                            {index === 0 && (
                                                <Select
                                                    className={"sort-select join-by-select"}
                                                    styles={selectStyles}
                                                    options={joinByOptions}
                                                    defaultValue={joinByOptions.filter(option => 
                                                        option.label === 'AND')
                                                    }
                                                    isSearchable={true}
                                                    isClearable={false}
                                                    name={"join_by"}
                                                    onChange={(option) => {
                                                        setJoinByGroup(option.label, filter.id);
                                                    }}
                                                />
                                            )}

                                            {index > 0 && (
                                                <div className="filter-title">
                                                    <p className="join-by-title">{ filter.groupJoin || 'AND' }</p>
                                                </div>
                                            )}
                                            
                                            {findSelectedColumnToSearch(filter.id)?.type === "date" && (
                                                <InputMask
                                                    mask="yyyy-mm-dd hh:mm:ss"
                                                    placeholder="yyyy-mm-dd hh:mm:ss"
                                                    className="sort-input"
                                                    replacement={{d: /\d/, m: /\d/, y: /\d/, h: /\d/, s: /\d/,}}
                                                    separate
                                                    value={getGroupSearchValue(index, filter.id) || ''}
                                                    onChange={e => {
                                                        updateGroupSearchValues(e.target.value, filter.id, index)
                                                    }}
                                                />
                                            )}

                                            {findSelectedColumnToSearch(filter.id)?.type !== "date" && (
                                                <div className={"search-bar-wrapper"}>
                                                <input
                                                    type="text"
                                                    className="sort-input"
                                                    value={getGroupSearchValue(index, filter.id) || ''}
                                                    onChange={(e) => {
                                                        updateGroupSearchValues(e.target.value, filter.id, index);
                                                    }}
                                                    placeholder="Search..."
                                                />
                                                    {errors && <p className="error-message">{errors}</p>}
                                                </div>
                                            )}

                                            {filters && filters.length < 1 && index === 0 && (
                                                <button className="close-btn"
                                                    onClick={() => removeNewFilterHandler(filter.id)}
                                                > +
                                                </button>
                                            )} 

                                            {index < 9 && (
                                                <button className="close-btn"
                                                    onClick={() => removeGroupValueHandler(filter.id, index)}
                                                > +
                                                </button>
                                            )} 
                                            
                                        </div>
                                    ))}
                                    
                                    {filter.groupValues && filter.groupValues.length < 9 && (
                                    <button className="no-border-btn"
                                                onClick={() => {addNewGroupValueHandler(filter.id)}}
                                            >
                                            + Value
                                        </button>
                                    )}
                                </div>
                            )}
                            
                        </div>
                    ))}
                    
                    {selectedANAccount && selectedANType && filters && filters.length < 10 && (
                        <div>
                            {/* <button className="no-border-btn"
                                    onClick={() => {addNewFilterHandler('filter')}}
                                >
                                + New Filter
                            </button> */}
                            {<button className="no-border-btn"
                                    onClick={() => {addNewFilterHandler('group')}}
                                >
                                + New Filter
                            </button>}
                        </div>
                    )} 
                </div>

                <div className="data-table-search-btns-wrap">
                    <ClearFilters
                        text={"Clear"}
                        clearFilters={clearSearchFilters}
                    />
                    <button
                        className={"blue-btn"}
                        onClick={getOffersWithSearchParams}
                        disabled={!Boolean(selectedColumnToSearch) || !Boolean(filters) || errors}
                    >
                        Apply
                    </button>
                </div>
            </div>

            <div className="data-table-order-wrap">
                {orderByOptions.length > 0 && (
                    <Select
                        className={"sort-select order-by-select"}
                        styles={selectStyles}
                        options={orderByOptions.sort((a, b) => a.value.localeCompare(b.value))}
                        defaultValue={selectedFilters.orderBy}
                        isSearchable={true}
                        placeholder="Select option:"
                        isClearable={true}
                        name={"order_by"}
                        onChange={(option) => {
                            changeSelectedFilters({
                                ...selectedFilters,
                                orderBy: option,
                            })
                        }}
                    />
                )}

                {orderDirectionOptions.length > 0 && (
                    <Select
                        className={"sort-select order-direction-select"}
                        styles={selectStyles}
                        options={orderDirectionOptions}
                        defaultValue={selectedFilters.orderDirection}
                        isSearchable={false}
                        name={"order_direction"}
                        onChange={(option) => {
                            changeSelectedFilters({
                                ...selectedFilters,
                                orderDirection: option,
                            })
                        }}
                    />
                )}
            </div>

            { selectedANAccount && selectedANType &&
                <div className="filter-table-tags">
                    {tags.length > 1 && joinByGeneralInTags && (
                        <p>{joinByGeneralInTags}</p>
                    )}
                    <ul className={"filter-tags-container"}>
                        {tags.length > 0 && tags.map((tag) => {

                            return <li key={tag.name} className={"filter-tags"}>
                                {tag.name.toLowerCase()}: <strong> {tag.operator.toLowerCase()} </strong> {tag.value.join(` ${tag.join_by} ` || ', ').toLowerCase()}
                                <button
                                    id={tag.name}
                                    onClick={() => handleRemoveColumnFromSearch(tag.name)}
                                ><img src="/images/close-icon.svg" alt="close"/></button>
                            </li>
                        })}
                    </ul>
                </div>
            }
        </div>
    )
}

export default AffiliateNetworkOffersTableFilter;