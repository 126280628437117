import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {accountInformationStart, accountSelected, setVoluumId} from "../../redux/dataUploader/dataSlice";
import {useLocation} from "react-router-dom";
import Loader from "../../components/loaders/Loader";
import OffersCheckerForm from "../../components/forms/OffersCheckerForm";
// import {closeVoluumOffers} from "../../services/voluum";
import {UPLOADER_TYPE_OFFERS} from "../../constants";
import {USER_PROJECT, USER_ROLE} from "../../constants/Auth";
import CustomSelect from "../../components/CustomSelect";

import '../YieldkitStatusChecker/yieldkit-status-checker.scss';

const ChangeVoluumByOffer = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        linksList: ''
    });
    const [currentValues, setCurrentValues] = useState({
        linksList: ''
    });
    const role = localStorage.getItem(USER_ROLE);
    const projectName = localStorage.getItem(USER_PROJECT);
    const menuStatus = useSelector(state => state.dataUploader.collapseMenu);
    const accountInformation = useSelector(state => state.dataUploader.accountInformation);
    const voluumId = useSelector(state => state.dataUploader.voluumId);
    const [isLoading, setLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('The file is too large!');
    const [accError, setAccError] = useState(false);
    const [fileLinksList, setFileLinksList] = useState([]);
    const [requestSuccess, setRequestSuccess] = useState({
        status: false,
        message: ''
    });
    const [requestError, setRequestError] = useState({
        status: false,
        message: ''
    });

    useEffect(() => {
        dispatch(accountInformationStart(projectName));
    }, [dispatch, projectName]);

    useEffect(() => {
        if(accountInformation.length > 0) {
            const firstAcc = accountInformation.reduce((prev, curr) => +prev['voluum_id'] < +curr['voluum_id'] ? prev : curr);
            dispatch(setVoluumId(firstAcc.voluum_id));
            dispatch(accountSelected(firstAcc.project));
        }
    }, [dispatch, accountInformation, pathname]);

    const sendData = async value => {
        if(voluumId === '') {
            setAccError(true);
        } else {
            setAccError(false);
            setLoading(true);
            let linksObj = {};
            const {linksList} = value;
            const linksArr = linksList
                .split(/[,\n\s]+/)
                .filter(link => link !== '' && /^[a-zA-Z0-9-]+$/.test(link))
                .filter((value, index, self) => self.indexOf(value) === index);

            const uniqueFileLinksList = fileLinksList.filter((value, index, self) => self.indexOf(value) === index);
            if(formError || linksList === '') {
                uniqueFileLinksList.forEach((el, i) => {
                    linksObj[i] = el;
                })
            } else {
                linksArr.forEach((el, i) => {
                    linksObj[i] = el;
                })
            }

            const data = {
                voluumAccountId: +voluumId,
                offers: JSON.stringify(linksObj)
            }

            const closeVoluumOffers = async (data) => {
                try {
                    const response = await fetch(`${localStorage.getItem('apiUrl')}api/voluum/deactivate-offers`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const results = await response.json();
                    return results;
                } catch (error) {
                    console.error('Error sending data:', error);
                    throw error;
                }
            };

            try {
                const results = await closeVoluumOffers(data);
                if(results.error) {
                    setRequestSuccess({
                        status: false,
                    })
                    setRequestError({
                        status: true,
                        message: results.data.error
                    })
                } else if(results) {
                    setRequestError({
                        status: false,
                    })
                    setRequestSuccess({
                        status: true,
                        message: 'Voluum offer ids successfully sent. Results will appear in Changed campaigns section when it\'s done.'
                    })
                }
                setLoading(false);
            } catch (err) {
                console.error(err);
                setRequestSuccess({
                    status: false,
                    message: ''
                })
                setRequestError({
                    status: true,
                    message: 'Oops.. Something went wrong!'
                })
                setLoading(false);
            }
        }
    }

    const validateOfferIds = value => {

        let error, valueArr, newArr;
        valueArr = value.split(/[,\n\s]+/);
        newArr = valueArr.filter(el => el && el !== '');

        if(fileLinksList.length > 30) {
            error = 'Maximum 30 offers per one request';
            setFormErrorMessage(error);
            setFormError(true);
        } else if(newArr.length > 30) {
            error = 'Maximum 30 offers per one request';
        } else if (!value && value === '' && fileLinksList.length === 0) {
            error = 'Required';
        } else if (value !== '' && fileLinksList.length !== 0) {
            setFormError(true);
        } else if (newArr.some(el => !/^[a-zA-Z0-9-]+$/.test(el))) {
            error = 'Please provide only valid offer ids';
        } else {
            setCurrentValues(previousState => {
                return {...previousState, linksList: value}
            });
        }

        return error;
    }

    if (isLoading) {
        return (
            <div className="loading-wrap">
                <Loader/>
            </div>
        )
    }

    return (
        <div className='yk-checker-page'>
            {role === 'admin' ?
                <>
                    <p className='yk-checker-text'>Please choose Voluum account for which you want to change voluum offers</p>
                    <CustomSelect
                        data={accountInformation.filter(acc => +acc['voluum_id'] < 1000)}
                        setDispatchVoluumId={true}
                    />
                    {accError ? <span className='yk-checker-form-error'>Please, choose account!</span> : null}
                </> :
                null
            }
            <p className='yk-checker-text'>Please insert the Voluum offer ids you want to close, divided by space, comma or new line</p>
            <div className={`form-wrap textarea-checker-form-wrap${!menuStatus ? ' collapsed-menu' : ''}`}>
                <OffersCheckerForm
                    type={UPLOADER_TYPE_OFFERS}
                    initialValues={initialValues}
                    currentValues={currentValues}
                    validate={validateOfferIds}
                    onSubmit={sendData}
                    isLoading={isLoading}
                    fileLinksList={fileLinksList}
                    setFileLinksList={setFileLinksList}
                    formError={formError}
                    setFormError={setFormError}
                    fileText={'Or upload a tab file with Voluum offer ids in one column'}
                />
                {formError ? <span className='yk-checker-form-error'>{formErrorMessage}</span> : null}
            </div>
            {requestSuccess.status ? <span className='new-user-success'>{requestSuccess.message}</span> : null}
            {requestError.status ? <span className='new-user-error'>{requestError.message}</span> : null}
        </div>
    )
}

export default ChangeVoluumByOffer;